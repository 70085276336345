<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-input(v-model="listQuery.originalname" clearable style="width: 200px;" placeholder="请输入文件名").filter-item
            el-button(v-permission="['system:file:list']" type="primary" icon="el-icon-search" @click="onFilter").filter-item.ml-5 查找

        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="right" label="ID" prop="fil_id" sortable)
            el-table-column(align="left" label="文件名" prop="originalname" sortable)
                template(slot-scope="scope")
                    img(v-if="canPreview(scope.row)" @click="onPreview(scope.row)" :src="scope.row.url").preview-img.pointer 
                    span {{scope.row.originalname}}

            el-table-column(align="right" label="文件大小" prop="size" sortable)
                template(slot-scope="scope")
                    span {{scope.row.size | numberFormatter}}
            el-table-column(align="right" label="上传时间" prop="creation_date" sortable)
                template(slot-scope="scope")
                    span {{scope.row.creation_date | formatDateAndTime}}
            el-table-column(align="right" label="上传帐号" prop="owner_account" sortable)
            el-table-column(align="center" label="操作").small-padding.fixed-width
                template(slot-scope="scope")
                    el-button(v-permission="['system:file:download']" type="primary" size="mini" @click="onDownload(scope.row)") 下载
                    el-button(v-permission="['system:file:delete']" type="primary" size="mini" @click="onDelete(scope.row)") 删除

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList")

        //- 弹出层
        el-dialog(:visible.sync="dialogVisible")
            img(width="100%" :src="dialogImageUrl" alt="")
</template>

<script>
import { getFileList, deleteFile } from "@/api/system";

import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

export default {
  name: "FileList",
  components: { Pagination },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        originalname: undefined,
        sort: "creation_date",
        order: "desc"
      },
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    canPreview(row) {
      return row.originalname.match(/^.+\.(jpg|jpeg|png)$/gim);
    },
    async getList() {
      this.listLoading = true;
      try {
        const resp = await getFileList(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    onDownload(row) {
      //
      const a = document.createElement("a");
      a.setAttribute("target", "_blank");
      a.setAttribute("href", row.url); //a.href='文件链接'
      a.setAttribute("download", row.originalname); //a.download='文件名'
      a.click();
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将删除该文件---" +
            row.originalname +
            " (id:" +
            row.fil_id +
            ")---, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        await deleteFile(row);

        // 提示操作成功
        this.$notify.success({
          title: "成功",
          message: "删除文件成功"
        });
        const index = this.list.indexOf(row);
        this.list.splice(index, 1);
      } catch (e) {
        return false;
      }
    },
    onPreview(row) {
      this.dialogVisible = true;
      this.dialogImageUrl = row.url;
    },
    onFilter() {
      this.listQuery.page = 1;
      this.getList();
    }
  }
};
</script>
