import request from "@/utils/request";

// 取得文件列表
export function getFileList(data) {
  return request({
    url: "/system/list-file",
    method: "post",
    data
  });
}

// 取得文件列表
export function deleteFile(data) {
  return request({
    url: "/system/delete-file",
    method: "post",
    data
  });
}

// 取得用户列表
export function getUserList(data) {
  return request({
    url: "/system/list-user",
    method: "post",
    data
  });
}

// 取得用户角色label
export function getRoleOptions() {
  return request({
    url: "/system/role-options",
    method: "post"
  });
}

// 取得用户label
export function getUserOptions() {
  return request({
    url: "/system/user-options",
    method: "post"
  });
}

//  新增用户
export function createUser(data) {
  return request({
    url: "/system/create-user",
    method: "post",
    data
  });
}

//  編辑用户
export function updateUser(data) {
  return request({
    url: "/system/update-user",
    method: "post",
    data
  });
}

//  删除用户
export function deleteUser(data) {
  return request({
    url: "/system/delete-user",
    method: "post",
    data
  });
}

//  取得权限列表
export function getRoleList(data) {
  return request({
    url: "/system/list-role",
    method: "post",
    data
  });
}

//  创建新角色
export function createRole(data) {
  return request({
    url: "/system/create-role",
    method: "post",
    data
  });
}

//   更新角色
export function updateRole(data) {
  return request({
    url: "/system/update-role",
    method: "post",
    data
  });
}
//   更新角色
export function deleteRole(data) {
  return request({
    url: "/system/delete-role",
    method: "post",
    data
  });
}

//  取得角色的权限
export function getPermission(data) {
  return request({
    url: "/system/get-permission",
    method: "post",
    data
  });
}

//  更新角色的权限
export function updatePermission(data) {
  return request({
    url: "/system/update-permission",
    method: "post",
    data
  });
}

//  取得访问日志
export function getAppLog(data) {
  return request({
    url: "/system/get-applog",
    method: "post",
    data
  });
}
